<template>
    <div class="root">
        <v-row class="mt-10">
            <v-col cols="2">
            </v-col>
            <v-col cols="8" align="center">
                <div v-if="userList.length>0">
                    <h2> Friend Requests! </h2>
                </div>
                <v-card elevation="0" outlined v-for="(u, index) in userList" :index="index" :key="index" class="my-2" style="max-width: 800px">
                    <v-card-text class=justify-center>
                        <div class="row">
                            <div class="col col-6">
                                <h2> <a :href="'/' + u.requester_channel_name">{{u.requester_channel_name}}</a> </h2>
                            </div>
                            <div class="col col-3">
                                <a @click="accept(u.requester_user_id)"> accept </a>
                            </div>
                            <div class="col col-3">
                                <a @click="decline(u.requester_user_id)"> decline </a>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2" />
        </v-row>
    </div>  
</template>
<script>
import api from '/src/utils/api';

const FriendInboundRequests = {
    name: 'FriendInboundRequests',
    components: {
    },
    data() {
        return {
            userList: [],
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            // console.log("FriendInboundRequests refresh");
            api.getCall('get-inbound-friend-requests').then(userList => {
                this.userList = userList;
            });
        },
        accept(friendUserId) {
            api.postAuthRequired('accept-friend-request', {friendUserId}).then(() => {
                this.refreshAllFriendData();
            });
        },
        decline(friendUserId) {
            api.postAuthRequired('decline-friend-request', {friendUserId}).then(() => {
                this.refreshAllFriendData();
            });
        },
    },
    props: ['refreshAllFriendData'],
};

export default FriendInboundRequests;
</script>
